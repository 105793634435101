@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans|Forum|Montserrat&display=swap&subset=cyrillic-ext);
body {
  margin: 0;
  background-position: center 0px;
  background-repeat: no-repeat;
  background-color: #fff;
  background-attachment: fixed;
  font-family: "Montserrat", sans-serif;
}

a:hover {
  text-decoration: none;
}

.App-navbar {
  background-color: #007bff;
}

.App-navbar > div > a {
  line-height: 40px;
  font-size: 16px;
  color: #fff;
  padding: 8px;
  transition: .3s all;
  border-radius: 10px;
  margin-right: 6px;
  margin-left: 6px;
}

.App-navbar > a {
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  padding: 5px;
  transition: .3s all;
  border-radius: 10px;
  margin-right: 6px;
  margin-left: 6px;
}

.App-navbar > div > a:hover, .App-navbar > a:hover {
  background-color: rgba(255, 255, 255, .2);
}

.App-footer {
  border-top: 2px solid #007bff;
  padding: 20px;
  background-color: #eee;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.App-content {
  padding: 20px;
}

.AdCard-image {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 150px;
  position: relative;
}

