@import url("https://fonts.googleapis.com/css?family=Alegreya+Sans|Forum|Montserrat&display=swap&subset=cyrillic-ext");

body {
  margin: 0;
  background-position: center 0px;
  background-repeat: no-repeat;
  background-color: #fff;
  background-attachment: fixed;
  font-family: "Montserrat", sans-serif;
}

a:hover {
  text-decoration: none;
}
